import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';

/**
 * Main Component
 */
class FormHeader extends React.Component {
  /**
   * Close contact modal.
   */
  closeModal() {
    // setTimeout(() => {
    //   window.scrollTo(0, document.body.scrollHeight ||
    //     document.documentElement.scrollHeight);
    // }, 500);
    document.documentElement.style.overflow = 'auto';
    this.props.setModal();
    this.setState({
      setContactForm: false,
      showContactForm: false,
    }, () => {
      this.handleOpenContactModal(this.state.showContactForm);
    });
  };

  /**
   *
   * @param {number} value text output val.
   */
  handleOpenContactModal(value) {
    this.props.onCloseContactModal(value);
  }

  /** @return {JSX} */
  render() {
    return (
      <div className="form-contact-header">
        <p className="title" style={{textTransform: 'uppercase'}} dangerouslySetInnerHTML={{
          __html: this.props.contactPdf ? (
          this.props.t('You would like to receive the plans of our {{property}} of the project {{project}}',
              {
                propertyType: this.props.property.generalizedTypeOfProperty,
                property: `<span class="color-green-secondary" id="nameOfProperty">${this.props.property.projectPropertyName}</span><br>`,
                project: `<span class="color-green-secondary" id="nameOfProperty">${this.props.project.projectName}</span>`,
              })
          ):(
          this.props.t('You wish to contact us about the project {{project}}', {
            project: `<span class="color-green-secondary" id="nameOfProperty">${this.props.project.projectName} - ${this.props.property.projectPropertyName}</span>`,
          })
          )}}/>
        <p className="form-type-text project-detail hide">
          {this.props.t('Visit an apartment on display')}
          <span id="unit_code">{this.props.property.unitCode}</span>
        </p>
        <button
          onClick={()=>{
            this.closeModal();
            document.documentElement.style.overflow = 'auto';
          }
          }
          className="modal-absolute-react-close-button"
          data-modal-close="modal-contact-form-react"
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
    );
  }
}

FormHeader.propTypes = {
  property: PropTypes.object,
  project: PropTypes.object,
  setModal: PropTypes.func,
  setContactForm: PropTypes.bool,
  closeModal: PropTypes.func,
  contactPdf: PropTypes.object,
  onCloseContactModal: PropTypes.func,
  t: PropTypes.func,
};

const mapStateToProps = (state) =>{
  return state;
};

export default connect(mapStateToProps,
    {},
)(withTranslation()(FormHeader));
