import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

/**
 * Main Component
 * @return {JSX.Element} The rendered component.
 */
const JobCard = ({job, countries}) => {
  const calculateHref = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const country = urlParams.get('country');
    const splitPath = decodeURI(window.location.pathname).split('/');
    const jobTitle = job.title.replace(/\//g, '-');
    return `${window.location.origin}/${splitPath[1]}/${splitPath[2]}/${jobTitle}?code=${job.code}${country ? `&country=${country}` : ''}`;
  };

  const {t} = useTranslation();
  const href = calculateHref();

  return (
    <div className='job-card'>
      <span className='job-card__country'>{job.jobCountry}</span>
      <span className='job-card__title'>{job.title}</span>
      <span className='job-card__employer'>{job.employer}</span>
      { job.geographicArea &&
        <span className='job-card__area'>
          <i className="fas fa-map-marker-alt" aria-hidden />
          <span>{job.geographicArea}</span>
        </span>
      }
      <div className='job-card__actions'>
        <a href={href} className='tp-btn--filled' type='button'>{t('Details')}</a>
        <a href={`${href}#postuler`} className='tp-btn--stroked' type='button'>{t('jobs.apply')}</a>
      </div>
    </div>
  );
};

JobCard.propTypes = {
  job: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
};

export default JobCard;
