import React, {useState, useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import {connect} from 'react-redux';
import {createRequestSearchProjects, createProjectList, createTotalProjects} from '../../pages/action';
import {
  defaultMapDisplay,
  createMarkerInBounds,
  createPOIData,
  createLockSearch,
  createMapLocation,
  createPopupProjectId,
} from './action';
import {showMessage, hideMessage} from '../InfoMessage/action';
import {BubleLoading} from '../../helpers/loading';
import {countryBounding, iconURL} from '../../helpers/helper';
import {loadList} from '../ProjectFilter/control/LoadListProject';
import PropTypes from 'prop-types';
import {calculateMarkerInBound} from './mapAction';
import PopupMarker from '../PopupMarker/PopupMarker';
import {toggleMapMobile, toggleMap} from '../MainMap/action';
import {useTranslation, withTranslation} from 'react-i18next';

/**
 * Class that can make main maps.
 */
class MainMap extends React.Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state ={
      loc: {},
      googleMapsObject: {},
      googleObject: {},
      isPopupActive: false,
      selectedProject: null,
      hoveringProject: null,
      popUpLocation: {
        lat: null,
        long: null,
      },
      onMapLoad: false,
      locationNotFound: false,
      zoom: null,
      center: {},
      reRender: false,
      countryCode: props.templateConfig.country_code,
      counterStartForm: 0,
      searchStartFrom: 2,
      isCountainingParameterUrl: false,
    };
    this.reRenderRef = React.createRef();
    this.googleMapRef = React.createRef();
    this.googleRef = React.createRef();
    this.markerslocationRef = React.createRef();
    this.markersRef = React.createRef();
    this.removeThePopUp = this.removeThePopUp.bind(this);
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * @return {object} props
   */
  static getDerivedStateFromProps(props, state) {
    if (props.mapLocation && props.mapLocation != true) {
      if (state.center !== props.mapLocation.center) {
        state.selectedProject = props.mapLocation.projectId;
        // state.reRender = true;
        state.onMapLoad = false;
      }
      state.center = props.mapLocation.center;
      state.zoom = props.mapLocation.zoom;
    } else {
      state.center = props.center;
      state.zoom = props.zoom;
    }
    return state;
  }

  /**
   * @return {void}
   */
  async componentDidMount() {
    this.setState({
      loc: defaultMapDisplay(this.state.countryCode),
    });

    // trigger dispatch create POI data
    this.props.createPOIData([]);
  }

  /**
   * @param {*} nextProps
   * @param {*} nextState
   * @return {void}
   */
  componentDidUpdate(nextProps, nextState) {
    try {
      // Handle for hovering project
      if (this.props.hoveringProject) {
        if (this.markersRef.current) {
          this.markersRef.current.map((item) => {
            if (this.props.hoveringProject.projectId === item.projectId) {
              const icon = {
                url: iconURL('map-pin-green.svg'),
                scaledSize: new google.maps.Size(32, 47),
              };
              item.setIcon(icon);
            } else {
              const icon = {
                url: iconURL('map-pin-red.svg'),
                scaledSize: new google.maps.Size(28, 41),
              };
              item.setIcon(icon);
            }
          });
        }
      }

      // handle if search location not found or reset filter location
      if (this.props.mapLocation.place_name === undefined) {
        if (this.markerslocationRef.current) {
          this.markerslocationRef.current.setMap(null);
          this.markerslocationRef.current = null;
        }
      }

      if (this.state.reRender) {
        if (this.reRenderRef) clearTimeout(this.reRenderRef);
        this.reRenderRef = setTimeout(() => this.setState({reRender: false}), 500);
      }

      if (this.props.markerCollection === null) {
        this.setState({
          onMapLoad: false,
        });
      }

      this.managePOI(this.state.googleMapsObject);

      // handle if change properti marker
      if (this.props.markerCollection !== nextProps.markerCollection) {
        // clear if pop-up active
        this.removeThePopUp();

        // recreate marker
        this.manageSingleMarker(this.props.markerCollection);

        // map should calculate the marker in Bound
        const markerInbounds = calculateMarkerInBound(
            this.state.googleMapsObject,
            this.props.markerCollection,
            this.googleRef,
        );

        // dispatch getMarkerInbounds
        this.props.createMarkerInBounds(markerInbounds);

        // handle if project not found
        if (markerInbounds !== undefined && markerInbounds.length === 0) {
          if (this.props.getToggleMap) {
            const projectIds = this.props.markerCollection.map(function(item) {
              return item.projectId;
            });
            this.manageProjectList(null, null, projectIds);
          } else {
            this.manageProjectList(null, null, []);
            this.setState({
              onMapLoad: true,
              locationNotFound: true,
            });
            this.state.googleMapsObject.setZoom(this.state.googleMapsObject.zoom - 1);
          }
        } else {
          const projectIds = markerInbounds.map(function(item) {
            return item.projectId;
          });
          this.manageProjectList(null, null, projectIds);
          this.setState({
            onMapLoad: true,
            locationNotFound: false,
          });
        }
      }
    } catch (error) {
      // ignore
    }
  }

  /**
   * Create single Marker
   * @param {array} locations
   *
   * @return {void}
   */
  manageSingleMarker(locations) {
    // clear all marker
    if (this.markersRef.current) {
      for (let i = 0; i < this.markersRef.current.length; i++) {
        this.markersRef.current[i].setMap(null);
      }
    }

    const arrLoc =[];
    locations.map((value)=>{
      arrLoc.push({
        projectId: value.projectId,
        lat: value.projectGeopoint.lat,
        lng: value.projectGeopoint.lon,
      });
    });
    const markers = arrLoc && arrLoc.map((location) => {
      const marker = new this.googleRef.Marker({
        position: location,
        projectId: location.projectId,
        icon: iconURL('map-pin-red.svg'),
      });

      // trigger display popUp from marker
      marker.addListener('click', () => {
        this.state.googleMapsObject.setCenter(marker.getPosition());
        this.popupProject(location.projectId);
      });
      marker.setMap(this.state.googleMapsObject);
      return marker;
    });
    this.markersRef.current = markers;
  }

  /**
   *
   * @param {object} map
   * @param {array} center
   * @param {*} thisClass
   */
  mapAction(map, center) {
    // set center maker of the location
    if (this.props.mapLocation.place_name !== undefined) {
      // clear current marker location
      if (this.markerslocationRef.current) {
        this.markerslocationRef.current.setMap(null);
      }

      // set location search marker
      const locationMarker = new this.googleRef.Marker({
        position: this.props.mapLocation.center,
      });
      locationMarker.setMap(map);
      this.markerslocationRef.current = locationMarker;
    } else {
      // clear current marker location
      if (this.markerslocationRef.current) {
        this.markerslocationRef.current.setMap(null);
        this.markerslocationRef.current = null;
      }
    }
    // map should calculate the marker in Bound
    const markerInbounds = calculateMarkerInBound(
        map,
        this.props.markerCollection,
        this.googleRef,
    );
    // dispatch getMarkerInbounds
    this.props.createMarkerInBounds(markerInbounds);
    // display poi
    this.managePOI(map);
    let mapZoom = map.zoom;
    // handle if project not found
    if (markerInbounds !== undefined && markerInbounds.length === 0) {
      this.setState({
        locationNotFound: true,
      });
      mapZoom = mapZoom - 1;
      if (this.state.onMapLoad && this.state.locationNotFound && !this.state.reRender) {
        this.props.showMessage({
          message: this.props.t('There are no projects for the searched location. Here are other projects nearby:'),
          variant: 'ERROR',
        });
      }
      if (this.state.onMapLoad && this.state.locationNotFound && mapZoom === 7 && !this.state.reRender) {
        this.props.showMessage({
          message: this.props.t('There are no projects matching your search. Please perform a new search.'),
          variant: 'ERROR',
        });
      }
    } else {
      this.props.hideMessage();
      this.setState({
        onMapLoad: true,
        locationNotFound: false,
      });
    }
    map.setZoom(mapZoom);
  }

  /**
   *
   * @param {*} map
   * @param {*} center
   * @return {void}
   */
  async loadProjects(map, center) {
    const markerInbounds = calculateMarkerInBound(
        map,
        this.props.markerCollection,
        this.googleRef,
    );
    // dispatch getMarkerInbounds
    this.props.createMarkerInBounds(markerInbounds);
    const projectIds = [];
    markerInbounds?.map((value)=>{
      projectIds.push(value.projectId);
    });

    let lat; let lng;
    if (center === undefined) {
      lat = null;
      lng = null;
    } else {
      lat = center.center.lat;
      lng = center.center.lng;
    }
    await this.manageProjectList(lat, lng, projectIds);
  }

  /**
   *
   * @param {*} lat
   * @param {*} lng
   * @param {*} projectIds
   * Handle project list
   */
  async manageProjectList(lat, lng, projectIds) {
    let params = await this.props.requestSearchProjects;
    params = {
      ...params,
      projectPropertiesSearchCriteria: {
        ...params.projectPropertiesSearchCriteria,
        projectIds: projectIds,
      },
      templateConfig: {
        ...this.props.templateConfig,
      },
      sorting: {
        ...params.sorting,
      },
    };
    if (lat !== null || lng !== null) {
      params = {
        ...params,
        sorting: {
          ...params.sorting,
          centeredCoorinates: {
            lat: lat,
            lon: lng,
          },
        },
      };
    }
    await this.props.createRequestSearchProjects({...params.projectPropertiesSearchCriteria, ...params.sorting, ...params.templateConfig});
    await this.props.createProjectList({'projects': [], 'loading': true, 'hasMore': true});
    await this.props.createTotalProjects({'loading': true, 'count': 0});
    const dataProjects = await loadList(params);
    await this.props.createProjectList({'projects': dataProjects.projects, 'loading': false, 'hasMore': (dataProjects.projects.length > 0)});
    await this.props.createTotalProjects({'loading': false, 'count': dataProjects.count});
  }

  /**
   *
   * @param {*} map
   * You only can show it on lv:15
   */
  managePOI(map) {
    const POI = new this.googleRef.StyledMapType(
        this.props.poiData,
        {name: 'custom'},
    );

    map.mapTypes.set('custom_style', POI);
    map.setMapTypeId('custom_style');
  }

  /**
   * @return {void}
   * @param {*} projectId
   */
  popupProject(projectId) {
    if (this.state.isPopupActive) {
      setTimeout(() => {
        this.props.createPopupProjectId(projectId);
        document.querySelector('.map-canvas-container').classList.add('popup-project-is-show');
      }, 200);
    } else {
      this.props.createPopupProjectId(projectId);
      document.querySelector('.map-canvas-container').classList.add('popup-project-is-show');
    }
  }

  /**
   * @return {void}
   */
  removeThePopUp() {
    if (this.state.isPopupActive) {
      this.props.createPopupProjectId(null);
      document.querySelector('.map-canvas-container').classList.remove('popup-project-is-show');
      this.setState({
        isPopupActive: false,
      });
    }
  }

  /** @return {void} */
  hideMapMobile() {
    this.props.toggleMapMobile(false);
  };

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    if (this.props.markerCollection === null || this.props.markerCollection.length === undefined) {
      return (
        <div className="container"
          style={{minHeight: 500, paddingTop: 200, background: 'transparent'}}>
          <BubleLoading text={this.props.t('Loading')}/>
        </div>);
    } else {
      return (
        <div
          className={`map-canvas-container ${this.props.getToggleMapMobile ? 'is-show' : ''}`}>
          <button className="btn-close-map-mobile" onClick = { () => this.hideMapMobile() } ><i className="fas fa-times"></i></button>
          <GoogleMapReact
            bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_API_KEY, v: '3.59', id: 'main_maps'}}
            resetBoundsOnResize={true}
            defaultCenter={this.state.loc.center}
            defaultZoom={this.state.loc.zoom}
            center={this.state.center}
            zoom={this.state.zoom}
            onChange={(center)=>{
              try {
                this.mapAction(this.state.googleMapsObject, center);
              } catch (error) {
                //
              }
            }}
            onTilesLoaded = {()=>{
              try {
                this.loadProjects(this.state.googleMapsObject);
              } catch (error) {
                //
              }
            }}
            onClick={()=> this.removeThePopUp()}
            yesIWantToUseGoogleMapApiInternals = {true}
            options={(maps) => {
              return createMapOptions(maps, this.state.countryCode);
            }}
            onGoogleApiLoaded={({map, maps}) =>{
              // assign class as state
              this.setState({
                googleMapsObject: map,
              });
              this.googleMapRef = map;
              this.googleRef = maps;

              // assign marker to the map
              this.manageSingleMarker(this.props.markerCollection);

              // centralize mapAction
              this.mapAction(map, {center: this.state.loc.center});
            }}

          >
          </GoogleMapReact>
          <Popup
            language={this.props.i18n.language}
            projectId = {this.props.popupProjectId}
            onLoad={() => {
              this.setState({
                isPopupActive: true,
              });
            }}
            removeThePopUp={this.removeThePopUp}
          />
          <LockControl
            mapProps={this.props}
          />
          <POIControl
            mapProps = {this.props}
          />
        </div>
      );
    }
  }
}

/**
 *
 * @param {*} maps
 * @param {*} countryCode
 * @return {any}
 */
function createMapOptions(maps, countryCode) {
  const bounds = countryBounding(countryCode);
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",

  return {
    zoomControlOptions: {
      position: maps.ControlPosition.TOP_LEFT,
    },
    fullscreenControl: false,
    mapTypeControlOptions: {
      mapTypeIds: [maps.MapTypeId.ROADMAP],
    },
    restriction: {
      latLngBounds: bounds,
      strictBounds: false,
    },
    minZoom: defaultMapDisplay.zoom,
    draggable: true,
    zoomControl: true,
    cameraControl: false,
    scrollwheel: true,
  };
}

/**
 * @param {*} maps
 * @return {any}
 */
function LockControl({mapProps}) {
  const {t} = useTranslation();
  const [check, setCheck] = useState(true);
  useEffect(()=>{
    mapProps.createLockSearch(check);
  }, [check]);
  return (
    <div className="input-container">
      <div className="form-checkbox-container">
        <div></div>
        <input
          type="checkbox"
          checked={check}
          id="_moving_lock"
          onChange={(e)=> setCheck(e.target.checked)}
          className="form-control"/>
        <label htmlFor="_moving_lock" >
          {t('Search when I move the map')}
        </label>
      </div>
    </div>
  );
}
/**
 * @param {*} maps
 * @return {any}
 */
function POIControl({mapProps}) {
  const [bus, setBus] = useState('');
  const [gradCap, setGradCap] = useState('');
  const [shop, setShop] = useState('');
  const [medical, setMedical] = useState('');
  const [gym, setGym] = useState('');


  useEffect(()=>{
    let {poiBus, poiGradCap, poiShop, poiMedical, poiGym} = 'off';

    poiBus = bus === 'is-active' ? 'on' :'off';
    poiGradCap = gradCap === 'is-active' ? 'on' :'off';
    poiShop = shop === 'is-active' ? 'on' :'off';
    poiMedical = medical === 'is-active' ? 'on' :'off';
    poiGym = gym === 'is-active' ? 'on' :'off';

    // dispatch POI
    mapProps.createPOIData({
      poiBus, poiGradCap, poiShop, poiMedical, poiGym,
    });
  }, [bus, gradCap, shop, medical, gym]);


  return (
    <div className="input-container near-facility-btn-group">
      <div className="filter-container">
        <button
          onClick={()=> bus == 'is-active' ? setBus(''): setBus('is-active')}
          className={`btn btn-map-filter ${bus}`}
        >
          <i className="fas fa-bus"></i>
        </button>
        <button
          className={`btn btn-map-filter ${gradCap}`}
          onClick={
            ()=> gradCap == 'is-active' ?
            setGradCap(''): setGradCap('is-active')
          }
        >
          <i className="fas fa-graduation-cap"></i>
        </button>
        <button
          onClick={()=> shop == 'is-active' ? setShop(''): setShop('is-active')}
          className={`btn btn-map-filter ${shop}`}
        >
          <i className="fas fa-shopping-cart"></i>
        </button>
        <button
          onClick={
            ()=> medical == 'is-active' ?
            setMedical(''): setMedical('is-active')
          }
          className={`btn btn-map-filter ${medical}`}
        >
          <i className="fas fa-briefcase-medical"></i>
        </button>
        <button
          onClick={
            ()=> gym == 'is-active' ?
            setGym(''): setGym('is-active')
          }
          className={`btn btn-map-filter ${gym}`}
        >
          <i className="fas fa-dumbbell"></i>
        </button>
      </div>
    </div>
  );
}

/**
 * @return {any}
 */
function Popup({projectId, onLoad, removeThePopUp, language}) {
  if (projectId) {
    return (
      <PopupMarker
        key={performance.now}
        projectId={projectId}
        language={language}
        onLoad={onLoad}
        removeThePopUp={removeThePopUp}
      />
    );
  } else {
    return '';
  }
}

MainMap.propTypes = {
  zoom: PropTypes.number,
  center: PropTypes.object,
  markerCollection: PropTypes.any,
  getParamsFiltering: PropTypes.object,
  requestSearchProjects: PropTypes.object,
  requestGetProjectMarkers: PropTypes.object,
  createRequestSearchProjects: PropTypes.func,
  createMarkerInBounds: PropTypes.func,
  createPOIData: PropTypes.func,
  poiData: PropTypes.array,
  createMapLocation: PropTypes.func,
  mapLocation: PropTypes.any,
  hoveringProject: PropTypes.any,
  popupProjectId: PropTypes.any,
  createPopupProjectId: PropTypes.func,
  isPopupActive: PropTypes.bool,
  createProjectList: PropTypes.func,
  showMessage: PropTypes.func,
  hideMessage: PropTypes.func,
  history: PropTypes.object,
  toggleMapMobile: PropTypes.func,
  getToggleMapMobile: PropTypes.bool,
  getToggleMap: PropTypes.bool,
  templateConfig: PropTypes.object,
  createTotalProjects: PropTypes.func,
  t: PropTypes.func,
  i18n: PropTypes.object,
};

POIControl.propTypes={
  mapProps: PropTypes.any,
};

LockControl.propTypes={
  mapProps: PropTypes.any,
};


const mapStateToProps = (state) =>{
  // we only care about requestSearchProjects and requestGetProjectMarkers.
  // so we can ignore the rest of the state to make memory faster.
  return {
    markerCollection: state.getMarkerCollection,
    requestSearchProjects: state.getRequestSearchProjects,
    requestGetProjectMarkers: state.getRequestGetProjectMarkers,
    poiData: state.getPOIData,
    lockSearch: state.getLockSearch,
    mapLocation: state.getMapLocation,
    hoveringProject: state.getHoveringProjectId,
    popupProjectId: state.getPopupProjectId,
    createProjectList: state.createProjectList,
    showMessage: state.showMessage,
    hideMessage: state.hideMessage,
    getToggleMap: state.getToggleMap,
    getToggleMapMobile: state.getToggleMapMobile,
    templateConfig: state.templateConfig,
  };
};

export default connect(mapStateToProps, {
  createRequestSearchProjects,
  createMarkerInBounds,
  createPOIData,
  createLockSearch,
  createMapLocation,
  createPopupProjectId,
  createProjectList,
  createTotalProjects,
  showMessage,
  hideMessage,
  toggleMapMobile,
  toggleMap,
})(withTranslation()(MainMap));
